body {
  background-color: #121212;
  color: #ffffff;
  margin: 0;
  font-family: Arial, sans-serif;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: flex-end;
  background-color: #1e1e1e;
  color: #ffffff;
}

.chat-header {
  padding: 15px;
  background-color: #333;
  text-align: center;
  border-bottom: 1px solid #444;
}
.chat-header {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.header-text {
  display: flex;
  align-items: center;
  font-size: 24px; /* Adjust font size as needed */
}

.header-logo {
  margin-left: 10px; /* Space between text and logo */
  width: 100px; /* Adjust width and height to match text size */
  height: 100px;
  filter: brightness(0) invert(1); /* Make the logo white */
}

.chat-box {
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.message {
  padding: 10px;
  margin: 5px 0;
  border-radius: 10px;
  max-width: 60%;
}

.message.user {
  background-color: #2a9d8f;
  align-self: flex-end;
  text-align: right;
}

.message.bot {
  background-color: #444;
  align-self: flex-start;
  text-align: left;
}

.loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  align-self: flex-start;
  margin: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.input-box {
  display: flex;
  padding: 10px;
  background-color: #333;
  border-top: 1px solid #444;
}

input[type="text"] {
  flex-grow: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #444;
  margin-right: 10px;
  background-color: #222;
  color: #ffffff;
}

button[type="submit"] {
  padding: 10px 20px;
  border: none;
  background-color: #e76f51;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #f4a261;
}

.empty-chat {
  margin: auto;
  color: #888;
  font-style: italic;
}